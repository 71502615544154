import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import { formSupplierOperations } from "state/ducks/formSupplier";
import { Row, Col } from "react-bootstrap";
import swal from "sweetalert";

/**
 * Permet de charger une candidature avec ton token
 */
const FormSupplierLoad = (props) => {
  const { initialValues, open, viewMode } = props;

  const [token, setToken] = useState(null);
  const [stepNumb, setStepNumb] = useState(null);
  const [email, setEmail] = useState(null);
  const [inProgress, setInProgress] = useState(
    initialValues.id !== null ? true : false
  );

  const dispatch = useDispatch();

  const handleTokenSubmit = (event) => {
    event.preventDefault();
    dispatch(formSupplierOperations.loadCandidateByToken(token)).then(
      (success) => { },
      (error) => {
        return swal({
          title: error,
          icon: "warning",
        });
      }
    );
  };

  const handleStepSubmit = (event) => {
    event.preventDefault();
    dispatch(formSupplierOperations.setStep(parseInt(stepNumb)));
  };
  const handleResetForm = () => {
    swal({
      title: "Etes-vous sûr de vouloir vous déconnecter ?",
      text: "Vous allez être déconnecté de la candidature en cours.\n\n Pensez à garder votre code de suivi de candidature et pensez à bien enregistrer vos modifications avant de vous déconnecter.",
      icon: "warning",
      buttons: ["Annuler", "Me déconnecter"],
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        setInProgress(false)
        dispatch(formSupplierOperations.resetCandidate());
      }
    });
  }

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      return swal({
        title: "Merci d'indiquer un email valide",
        icon: "warning",
      });
    }
    if (initialValues.id) {
      swal({
        title: "Une candidature est déjà existante pour cet email.",
        icon: "warning",
        dangerMode: true,
        buttons: ["Annuler"],
      }).then((confirm) => {
        if (confirm) {
          dispatch(formSupplierOperations.initCandidate(email)).then(
            (success) => {
              swal({
                title:
                  "Vous allez recevoir un code de suivi sur votre email : " +
                  email +
                  " à renseigner à l'étape suivante",
                icon: "success",
              });
            }
          );
        }
      });
    } else {
      dispatch(formSupplierOperations.initCandidate(email)).then((success) => {
        swal({
          title:
            "Vous allez recevoir un code de suivi sur votre email : " +
            email +
            " à renseigner à l'étape suivante",
          icon: "success",
        });
      }).catch((error) => {
        swal({
          title: "Erreur",
          text: "Une candidature est déjà existante pour cet email.",
          icon: "error",
          button: "OK",
        });
      });
    }
  };

  return (
    <>
      {!open ? (
        <p className="text-center">Les candidatures sont actuellement closes</p>
      ) : (
        <>
          {inProgress === true ? (
            <p className="text-center">
              <button
                className="btn-green min mt-3"
                onClick={() => handleResetForm()}
              >
                Se déconnecter et faire une nouvelle demande
              </button>
            </p>
          ) : (
            <Row>
              <Col xs={12}>
                <p className="text-center mt-4">
                  <b>
                    Pour commencer ou reprendre une candidature merci de suivre
                    les étapes ci-dessous.
                  </b>
                </p>
              </Col>
              <Col sm={{ span: 3, offset: 3 }}>
                <div className="login">
                  <div className="block">
                    <form onSubmit={handleEmailSubmit}>
                      <FormGroup>
                        <h4 className="mt-0">
                          J'obtiens mon code de suivi pour démarrer une nouvelle
                          candidature
                        </h4>
                        <Label className="mb-2">
                          Je récupère mon code suivi dans mon email
                        </Label>
                        <Input
                          required
                          name="email"
                          type="email"
                          placeholder="Renseigner votre email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type="submit" className="btn-green mt-3">
                          Recevoir mon code par email
                        </button>
                      </FormGroup>
                    </form>
                  </div>
                </div>
              </Col>
              <Col sm={3}>
                <div className="login">
                  <div className="block">
                    <form onSubmit={handleTokenSubmit}>
                      <FormGroup>
                        <h4 className="mt-0">
                          Je renseigne mon code de suivi candidature
                        </h4>
                        <Label className="mb-2">
                          Pour commencer ou reprendre ma candidature
                        </Label>
                        <Input
                          name="token"
                          type="text"
                          placeholder="Mon code reçu par email"
                          onChange={(e) => setToken(e.target.value)}
                          required
                        />
                        <button type="submit" className="btn-green mt-3">
                          Je commence/reprends ma candidature
                        </button>
                      </FormGroup>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {(viewMode === true) && (
            <form onSubmit={handleStepSubmit} className="fix-form-dev">
              <Input
                name="step"
                type="number"
                onChange={(e) => setStepNumb(e.target.value - 1)}
                placeholder="Etape"
                required
              />
              <button type="submit" className="btn-green mt-3">
                GO
              </button>
            </form>
          )}
        </>
      )}
    </>
  );
};

FormSupplierLoad.propTypes = {};

FormSupplierLoad.defaultProps = {};

export default FormSupplierLoad;
