import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import FormSupplier from "./FormSupplier";
import FormSupplierError from "./FormSupplierError";
import { storeOperations } from "state/ducks/store";
import { formSupplierOperations } from "state/ducks/formSupplier";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import { formSupplierSelectors } from "state/ducks/formSupplier";
import { storeSelectors } from "state/ducks/store";
import Step1 from "./Steps/Step1/Step1";
import Step2Container from "./Steps/Step2/Step2Container";
import Step3Container from "./Steps/Step3/Step3Container";
import Step4Container from "./Steps/Step4/Step4Container";
import Step5Container from "./Steps/Step5/Step5Container";
import Step6Container from "./Steps/Step6/Step6Container";
import Step7Container from "./Steps/Step7/Step7Container";
import Step8Container from "./Steps/Step8/Step8Container";
import Step9Container from "./Steps/Step9/Step9Container";
import Step10AltContainer from "./Steps/Step10Alt/Step10AltContainer";
import Step11Container from "./Steps/Step11/Step11Container";
import Step12Container from "./Steps/Step12/Step12Container";
import Step12Phase2Container from "./Steps/Step12Phase2/Step12Phase2Container";
import swal from "sweetalert";

const FormSupplierContainerPhase2 = (props) => {
  const forThePhase = "2";
  const [isLoading] = useState(false);

  const phaseCandidate = process.env.REACT_APP_CANDIDATE_PHASE; //string
  const closeCandidate =
    process.env.REACT_APP_CLOSE_CANDIDATE === "true" ? true : false;

  const formRefStep2 = useRef("step2");
  const formRefStep3 = useRef("step3");
  const formRefStep4 = useRef("step4");
  const formRefStep5 = useRef("step5");
  const formRefStep6 = useRef("step6");
  const formRefStep7 = useRef("step7");
  const formRefStep8 = useRef("step8");
  const formRefStep9 = useRef("step9");
  const formRefStep10Alt = useRef("step10Alt");
  const formRefStep11 = useRef("step11");
  const formRefStep12 = useRef("step12");

  const dispatch = useDispatch();

  const edition = process.env.REACT_APP_CANDIDATE_EDITION;

  const departments = useSelector(
    (state) => state.store.departments,
    shallowEqual
  );

  const categoriesLot = useSelector(
    (state) => state.store.categoriesLot,
    shallowEqual
  );

  const lots = useSelector((state) => state.store.lots, shallowEqual);

  // Recupère le selector des regions des lots
  const regions = useSelector((state) => {
    return storeSelectors.regionsSelector(state);
  });

  // Recupère le selector des contractants au contrat
  const contractors = useSelector((state) => {
    return formSupplierSelectors.contractorsSelector(state);
  });

  // Valeur initiale du formulaire redux
  const initialValues = useSelector((state) => {
    return formSupplierSelectors.candidateSelector(state);
  });

  
  // 05/23 - View mode
  const viewMode = props?.match?.params?.viewMode === '1' ? true : false;
  const token = props.match.params.token;

  if (viewMode === true && token) {
    if (
      ((initialValues.init && initialValues.token !== token) || (initialValues.id === null) )
    && initialValues.isLoading === false) {
      dispatch(formSupplierOperations.loadCandidateByToken(token)).then(
        (success) => {
        },
        (error) => {
          return swal({
            title: error,
            icon: "warning",
          });
        }
      );
    }
  }

  useEffect(() => {
    // Modifions la logique de déconnexion pour être plus robuste
    const handleFirstVisit = () => {
      if (!sessionStorage.getItem('visited')) {
        // Nettoyage complet 
        sessionStorage.clear();
        localStorage.clear();
        // Reset de l'état Redux
        dispatch(formSupplierOperations.resetCandidate());
        // Marquer comme visité
        sessionStorage.setItem('visited', 'true');
        window.location.href = '/depot-offre'; // Ajustez l'URL selon votre besoin
      }
    };
  
    // Exécuter au montage du composant
    handleFirstVisit();
  
    // Ajouter un listener pour le rafraîchissement
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('visited');
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);


  useEffect(() => {
    if (!departments.init) {
      dispatch(storeOperations.getDepartments());
    }
    if (!categoriesLot.init || !lots.init) {
      dispatch(storeOperations.getLots(27, edition));
      dispatch(storeOperations.getCategoriesLot(edition));
    }
    if (
      (categoriesLot.init || lots.init) &&
      !categoriesLot.isLoading &&
      !lots.isLoading &&
      (parseInt(categoriesLot.edition) !== parseInt(edition) ||
        parseInt(lots.edition) !== parseInt(edition))
    ) {
      dispatch(storeOperations.getLots(27, edition));
      dispatch(storeOperations.getCategoriesLot(edition));
    }
    // Il faut que les lots soient chargés en amont pour avoir le listing des articles
    if (lots.loaded && !initialValues.init) {
      dispatch(formSupplierOperations.loadCandidate(initialValues.id));
    }
    // Si la candidature n'a pas d'id et qu'on est après l'étape 1, pas normal, remet à l'étape 1
    if (!initialValues.id && initialValues.stepInprogress > 1) {
      dispatch(formSupplierOperations.setStepInprogress(1));
    }
  }, [dispatch, lots, departments, categoriesLot, initialValues, edition]);

  /**
   * Permet de définir les labels des Steppers
   */
  const getSteps = () => {
    return [
      "Préambule",
      "Objet de la candidature",
      "Présentation du candidat",
      "Coordonnées détaillées du candidat ou du groupement",
      "Renseignements relatifs à l’aptitude à exercer l’activité professionnelle concernée par le contrat",
      "Renseignements relatifs à la capacité économique et financière du candidat individuel ou des membres du groupement",
      "Renseignements relatifs à la capacité technique et professionnelle du candidat individuel ou du membre du groupement",
      "Capacités des opérateurs économiques sur lesquels le candidat individuel ou le membre du groupement s’appuie pour présenter sa candidature",
      "Mémoire technique",
      "Offre financière",
      "Récapitulatif",
      "Signature de l'accord cadre",
    ];
  };

  /**
   * Permet de définir les composants utilisés pour telle Step
   */
  const getStepContent = (step) => {
    let comp;
    switch (step) {
      case 0:
        comp = <Step1 />;
        break;
      case 1:
        comp = (
          <Step2Container
            formRef={formRefStep2}
            departments={departments}
            lots={lots}
            categoriesLot={categoriesLot}
            initialValues={initialValues}
            regions={regions}
            phaseCandidate={phaseCandidate}
          />
        );
        break;
      case 2:
        comp = (
          <Step3Container
            formRef={formRefStep3}
            initialValues={initialValues}
          />
        );
        break;
      case 3:
        comp = (
          <Step4Container
            formRef={formRefStep4}
            initialValues={initialValues}
            contractors={contractors}
          />
        );
        break;
      case 4:
        comp = (
          <Step5Container
            formRef={formRefStep5}
            initialValues={initialValues}
          />
        );
        break;
      case 5:
        comp = (
          <Step6Container
            formRef={formRefStep6}
            initialValues={initialValues}
          />
        );
        break;
      case 6:
        comp = (
          <Step7Container
            formRef={formRefStep7}
            initialValues={initialValues}
            contractors={contractors}
          />
        );
        break;
      case 7:
        comp = (
          <Step8Container
            formRef={formRefStep8}
            initialValues={initialValues}
            contractors={contractors}
          />
        );
        break;
      case 8:
        comp = (
          <Step9Container
            formRef={formRefStep9}
            initialValues={initialValues}
            contractors={contractors}
          />
        );
        break;
      case 9:
        comp = (
          <Step10AltContainer
            formRef={formRefStep10Alt}
            initialValues={initialValues}
            viewMode={viewMode}
          />
        );
        break;
      case 10:
        comp = (
          <Step11Container
            formRef={formRefStep11}
            initialValues={initialValues}
          />
        );
        break;
      case 11:
        if (false) {
          comp = <Step12Phase2Container initialValues={initialValues} />;
        } else {
          comp = <Step12Container initialValues={initialValues} />;
        }
        break;
      default:
        comp = "Etape inconnu";
        break;
    }
    return comp;
  };

  const handleNext = () => {
    dispatch(
      formSupplierOperations.setStepInprogress(initialValues.stepInprogress + 1)
    );
  };

  /**
   * Permet de définir quelle fonction est utilisée lors du passage à l'étape suivante
   * 13/09/2022 - Ajout de cancelable: true pour que le prevent default fonctionne sur firefox
   */
  const getStepFunction = async (step) => {
    switch (step) {
      case 0:
        handleNext();
        break;
      case 1:
        formRefStep2.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 2:
        formRefStep3.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 3:
        formRefStep4.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 4:
        formRefStep5.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 5:
        formRefStep6.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 6:
        formRefStep7.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 7:
        formRefStep8.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 8:
        formRefStep9.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 9:
        dispatch(
          formSupplierOperations.setStep10Alt(
            { token: initialValues.token },
            initialValues.id
          )
        );
        break;
      case 10:
        formRefStep11.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      case 11:
        formRefStep12.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
        break;
      default:
        break;
    }
  };

  const isNextDisable = (step) => {
    let disable = false;
    switch (step) {
      case 1:
        break;
      default:
        break;
    }
    return disable;
  };

  const steps = getSteps();

  const handleBack = () => {
    dispatch(
      formSupplierOperations.setStepInprogress(initialValues.stepInprogress - 1)
    );
  };

  const handleReset = () => {
    dispatch(formSupplierOperations.resetCandidate());
  };

  const [generatePreviewPDF, setGeneratePreviewPDF] = useState(true);
  useEffect(() => {
    // Important car permet de lancer la génération du preview pdf après la chargement de la candidature
    if (initialValues.loaded) {
      if (initialValues.stepInprogress < 10) {
        setGeneratePreviewPDF(true);
      }
      if (
        initialValues.init &&
        generatePreviewPDF &&
        initialValues.stepInprogress === 10 &&
        viewMode !== true
      ) {
        setGeneratePreviewPDF(false);
        dispatch(
          formSupplierOperations.generatePDFCandidate(
            "preview",
            initialValues.id
          )
        );
      }
    }
  }, [dispatch, initialValues, generatePreviewPDF, setGeneratePreviewPDF, viewMode]);

  return (
    <>
      {initialValues.isLoading || lots.isLoading ? (
        <>
          <GlobalLoader
            message={
              initialValues.stepInprogress === 10 ||
              initialValues.stepInprogress === 11
                ? "Génération de votre contrat en cours, merci de patienter, cela peut prendre plusieurs minutes ..."
                : "Chargement en cours, merci de ne pas fermer cette fenêtre."
            }
          />
          <FormSupplier
            activeStep={initialValues.stepInprogress}
            steps={steps}
            getStepFunction={getStepFunction}
            handleBack={handleBack}
            isNextDisable={isNextDisable}
            isLoading={isLoading}
            handleReset={handleReset}
            getStepContent={getStepContent}
            initialValues={initialValues}
            forThePhase={forThePhase}
            phaseCandidate={phaseCandidate}
            closeCandidate={closeCandidate}
            viewMode={viewMode}
          />
        </>
      ) : initialValues?.criticalError?.type ? (
        <FormSupplierError
          errorType={initialValues.criticalError.type}
          handleAction={handleReset}
        />
      ) : (
        <FormSupplier
          activeStep={initialValues.stepInprogress}
          steps={steps}
          getStepFunction={getStepFunction}
          handleBack={handleBack}
          isNextDisable={isNextDisable}
          isLoading={isLoading}
          handleReset={handleReset}
          getStepContent={getStepContent}
          initialValues={initialValues}
          forThePhase={forThePhase}
          phaseCandidate={phaseCandidate}
          closeCandidate={closeCandidate}
          viewMode={viewMode}
        />
      )}
    </>
  );
};

export default FormSupplierContainerPhase2;
